import loadable from "@loadable/component";
import { getDependSheets } from "../../schema/allDependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeBatchGantt: StrictViewType = {
  key: "planiumChemicalBatchGanttView",
  displayName: "ガントチャート",
  dependSheets: getDependSheets([
    "batchTaskMaster",
    "batchTaskPlan",
    "batchTaskProcessTimeMaster",
    "batchTaskRawMaterialPlan",
    "batchTaskStoragePlan",
    "bomByproductItemMaster",
    "bomMaster",
    "bomRawMaterialMaster",
    "deviceMaster",
    "deviceMaintenanceInput",
    "itemMaster",
    "storageItemMaster",
    "switchingBatchTaskMaster",
    "taskDeviceTypeMaster",
    "params",
    "businessDayCalendar",
    "linkTaskMaster",
    "scoreSummaries",
    "utilityCostTypeMaster",
    "batchTaskUtilityMaster",
    "bitMaskToPeopleCountMaster",
    "batchTaskToPeopleCountMaster",
    "salesInput",
    "shippingPlan",
    "rawMaterialMaster",
    "purchasePlan",
    "storageMaster",
    "adjustmentInput",
  ]),
  configSchemas: [],
  render: ViewRender as any,
};

import { ViewTypeDependSheet } from "@/domain/ViewTypeDependSheet";
import { SpreadSheetSetting } from "../../types";
type RowKeys = {
  [k in keyof viewtypeContents]: keyof viewtypeContents[k] & string;
};
type SheetKey = keyof RowKeys & string;

export type viewtypeContents = {
  setting: {
    optimizationStartDay: string;
    optimizationEndDay: string;
    optimizationEo: string;
    optimizationEg: string;
    optimizationNvp: string;
    optimizationEa: string;
    optimizationEi: string;
    optimizationBhea: string;
    optimizationEoa: string;
    optimizationSft: string;
    optimization4s: string;
    optimization4sNoTank: string;
    timeCoefficient123s: string;
    timeCoefficient4s: string;
    maxDaysToDivideCalculation: string;
    maxDaysToOptimizeCalculation: string;
    annualPlanMode: string;
  };
  productSheetWithout4sMetaData: {
    item_id: string;
    en_item_name: string;
    item_name: string;
    item_kind: string;
    data_type: string;
    enum_params: string;
    algorithm_kind: string;
    target_item_category: string;
    target_item: string;
    consume_item_category: string;
    consume_item: string;
    reactor: string;
    tank_id: string;
    department: string;
    output_kind: string;
  };
  parameter: {
    nonEgRateChidori: string;
    nonEgRateUkishima: string;
    egYieldChidori: string;
    egYieldUkishima: string;
    megRateChidori: string;
    megRateUkishima: string;
    etUtilizationRateChidori: string;
    catalystAmountChidori: string;
    feedWaterTemperatureChidori: string;
    gasEnthalpyChidori: string;
    ceSChidori: string;
    ceTChidori: string;
    steamCorrectionChidori: string;
    steamCorrectionEa: string;
    totalEgSteamConsumptionChidori: string;
    eoStopCostPerDayChidori: string;
    eoStopCostRestartChidori: string;
    etUtilizationRateUkishima: string;
    catalystAmountUkishima: string;
    feedWaterTemperatureUkishima: string;
    gasEnthalpyUkishima: string;
    ceSUkishima: string;
    ceTUkishima: string;
    steamCorrectionUkishima: string;
    totalEgSteamConsumptionUkishima: string;
    eoStopCostPerDayUkishima: string;
    eoStopCostRestartUkishima: string;
    score4sLowerLimit: string;
    score4sUpperLimit: string;
    score4sTargetStockMid: string;
    score4sTargetStockEnd: string;
    score4sEoConsumptionLowerLimit: string;
    score4sEoConsumptionUpperLimit: string;
    score4sEoConsumptionEven: string;
    score4sSwitchingTimes: string;
    sft70And90ContinuousStopDaysLowerLimit: string;
    sft70And90ContinuousStopDaysUpperLimit: string;
    sft70ContinuousOperationDaysLowerLimit: string;
    sft70ContinuousOperationDaysUpperLimit: string;
    sft90ContinuousOperationDaysLowerLimit: string;
    sft90ContinuousOperationDaysUpperLimit: string;
    nvpContinuousStopDaysLowerLimit: string;
    nvpContinuousStopDaysUpperLimit: string;
    nvpContinuousOperationDaysLowerLimit: string;
    nvpContinuousOperationDaysUpperLimit: string;
    eaContinuousStopDaysLowerLimit: string;
    eaContinuousStopDaysUpperLimit: string;
    eaContinuousOperationDaysLowerLimit: string;
    eaContinuousOperationDaysUpperLimit: string;
  };
  penalty_weight: {
    item1: string;
    item2: string;
    item3: string;
    penaltyCoefficient: string;
  };
  catalyst_parameter: {
    factory: string;
    catalystId: string;
    target: string;
    startEo: string;
    coefficientA: string;
    coefficientB: string;
    coefficientC: string;
  };
  product_master: {
    product_id: string;
    product_name: string;
    group_name: string;
    place: string;
    remark: string;
  };
  product_group_master: {
    group_name: string;
  };
  reactor_product_master: {
    product_id: string;
    reactor_id: string;
    reactor_production_time: string;
    shipping_production_time: string;
    production_amount: string;
    tank_id: string;
    direct: string;
  };
  material_master: {
    raw_material_id: string;
    raw_material_name: string;
    place: string;
    remark: string;
  };
  product_material_master: {
    product_id: string;
    raw_material_id: string;
    reactor_id: string;
    required_amount: string;
  };
  tank_master: {
    tank_name: string;
    tank_id: string;
    product_id: string;
    capacity_upper_limit: string;
    capacity_lower_limit: string;
    target_stock_amount: string;
  };
  switch_master: {
    reactor_id: string;
    prev_product_id: string;
    next_product_id: string;
    switching_time: string;
  };
  unavailable_reactor: {
    id: string;
    reactor_id: string;
    start_datetime: string;
    end_datetime: string;
  };
  unavailable_tank: {
    tank_id: string;
    start_datetime: string;
    end_datetime: string;
    force_transport: string;
  };
  s4_master_1234s: {
    itemCode: string;
    itemText: string;
    version: string;
    stockArea: string;
    lotSize: string;
  };
  general: {
    date: string;
    holiday: string;
    chidoriCatalystID: string;
    ukishimaCatalystID: string;
    chidoriAirFlow: string;
    ukishimaAirFlow: string;
    chidoriSummationEO: string;
    chidoriElectricityPrice: string;
    chidoriGasPrice: string;
    chidoriSteamPrice: string;
    ukishimaSummationEO: string;
    ukishimaElectricityPrice: string;
    ukishimaGasPrice: string;
    ukishimaSteamPrice: string;
    ukishimaEOConsumption4s: string;
    ukishimaMaximumEOConsumption4s: string;
    ukishimaMinimumEOConsumption4s: string;
    sft30Consumption4s: string;
    sft33Consumption4s: string;
    sft70Consumption4s: string;
    sft90Consumption4s: string;
    shortage4s24k417n: string;
    ethyleneCostForEO: string;
    ethyleneCostForEG: string;
    EOMarginalProfitPerTonne: string;
    domesticMegMarginalProfitPerTonne: string;
    exportMegMarginalProfitPerTonne: string;
    domesticDegMarginalProfitPerTonne: string;
    exportDegMarginalProfitPerTonne: string;
    tegMarginalProfitPerTonne: string;
    traegMarginalProfitPerTonne: string;
    nparaffinCost: string;
    ammoniaCost: string;
    calcCost000: string;
    calcCost001: string;
    calcCost002: string;
    calcCost003: string;
    calcCost004: string;
    calcCost005: string;
    calcCost006: string;
    calcCost007: string;
    calcCost008: string;
    calcCost009: string;
    calcCost010: string;
    calcCost011: string;
    calcCost012: string;
    calcCost013: string;
    calcCost014: string;
    calcCost015: string;
    calcCost016: string;
    calcCost017: string;
    calcCost018: string;
    calcCost019: string;
    calcCost020: string;
    calcCost021: string;
    calcCost022: string;
    calcCost023: string;
    calcCost024: string;
    calcCost025: string;
    calcCost026: string;
    calcCost027: string;
    calcCost028: string;
    calcCost029: string;
    calcCost030: string;
    calcCost031: string;
    calcCost032: string;
    calcCost033: string;
    calcCost034: string;
    calcCost035: string;
    calcCost036: string;
    calcCost037: string;
    calcCost038: string;
    calcCost039: string;
    calcCost040: string;
    calcCost041: string;
    calcCost042: string;
    calcCost043: string;
    calcCost044: string;
    calcCost045: string;
    calcCost046: string;
    calcCost047: string;
    calcCost048: string;
    calcCost049: string;
    calcCost050: string;
    calcCost051: string;
    calcCost052: string;
    calcCost053: string;
    calcCost054: string;
    calcCost055: string;
    calcCost056: string;
    calcCost057: string;
    calcCost058: string;
    calcCost059: string;
    calcCost060: string;
    calcCost061: string;
    calcCost062: string;
    calcCost063: string;
    calcCost064: string;
    calcCost065: string;
    calcCost066: string;
    calcCost067: string;
    calcCost068: string;
    calcCost069: string;
    calcCost070: string;
    calcCost071: string;
    calcCost072: string;
    calcCost073: string;
    calcCost074: string;
    calcCost075: string;
    calcCost076: string;
    calcCost077: string;
    calcCost078: string;
    calcCost079: string;
    calcCost080: string;
    calcCost081: string;
    calcCost082: string;
    calcCost083: string;
    calcCost084: string;
    calcCost085: string;
    calcCost086: string;
    calcCost087: string;
    calcCost088: string;
    calcCost089: string;
    calcCost090: string;
    calcCost091: string;
    calcCost092: string;
    calcCost093: string;
    calcCost094: string;
    calcCost095: string;
    calcCost096: string;
    calcCost097: string;
    calcCost098: string;
    calcCost099: string;
    calcCost100: string;
    calcCost101: string;
    calcCost102: string;
    calcCost103: string;
    calcCost104: string;
    calcCost105: string;
    calcCost106: string;
    calcCost107: string;
    calcCost108: string;
    calcCost109: string;
    calcCost110: string;
    calcCost111: string;
    calcCost112: string;
    calcCost113: string;
    calcCost114: string;
    calcCost115: string;
    calcCost116: string;
    calcCost117: string;
    calcCost118: string;
    calcCost119: string;
    calcCost120: string;
    calcCost121: string;
    calcCost122: string;
    calcCost123: string;
    calcCost124: string;
    calcCost125: string;
    calcCost126: string;
    calcCost127: string;
    calcCost128: string;
    calcCost129: string;
    calcCost130: string;
    calcCost131: string;
    calcCost132: string;
    calcCost133: string;
    calcCost134: string;
    calcCost135: string;
    calcCost136: string;
    calcCost137: string;
    calcCost138: string;
    calcCost139: string;
    calcCost140: string;
    calcCost141: string;
    calcCost142: string;
    calcCost143: string;
    calcCost144: string;
    calcCost145: string;
    calcCost146: string;
    calcCost147: string;
    calcCost148: string;
    calcCost149: string;
    calcCost150: string;
    calcCost151: string;
    calcCost152: string;
    calcCost153: string;
    calcCost154: string;
    calcCost155: string;
    calcCost156: string;
    calcCost157: string;
    calcCost158: string;
    calcCost159: string;
    calcCost160: string;
    calcCost161: string;
    calcCost162: string;
    calcCost163: string;
    calcCost164: string;
    calcCost165: string;
    calcCost166: string;
    calcCost167: string;
    calcCost168: string;
    calcCost169: string;
    calcCost170: string;
    calcCost171: string;
    calcCost172: string;
    calcCost173: string;
    calcCost174: string;
    calcCost175: string;
    calcCost176: string;
    calcCost177: string;
    calcCost178: string;
    calcCost179: string;
    calcCost180: string;
    calcCost181: string;
    calcCost182: string;
    calcCost183: string;
    calcCost184: string;
    calcCost185: string;
    calcCost186: string;
    calcCost187: string;
    calcCost188: string;
    calcCost189: string;
    calcCost190: string;
    calcCost191: string;
    calcCost192: string;
    calcCost193: string;
    calcCost194: string;
    calcCost195: string;
    calcCost196: string;
    calcCost197: string;
    calcCost198: string;
    calcCost199: string;
    calcCost200: string;
    calcCost201: string;
    calcCost202: string;
    calcCost203: string;
    calcCost204: string;
    calcCost205: string;
    calcCost206: string;
    calcCost207: string;
    calcCost208: string;
    calcCost209: string;
    calcCost210: string;
    calcCost211: string;
    calcCost212: string;
    calcCost213: string;
    calcCost214: string;
    calcCost215: string;
    calcCost216: string;
    calcCost217: string;
    calcCost218: string;
    calcCost219: string;
    calcCost220: string;
    calcCost221: string;
    calcCost222: string;
  };
  production: {
    reactor_id: string;
    product_id: string;
    product_name: string;
    tank_id: string;
    start_datetime: string;
    end_datetime: string;
    production_type: string;
    is_fixed: string;
    task_id: string;
    comment: string;
  };
  transport: {
    datetime: string;
    tank_id_from: string;
    tank_id_to: string;
    amount: string;
    is_fixed: string;
    task_id: string;
  };
  shipping_schedule: { [key: string]: string };
  tank_amount: { [key: string]: string };
  product_tank_amount: { [key: string]: string };
  material_consumption_by_product: { [key: string]: string };
  material_consumption_by_group: { [key: string]: string };
  raw_material_consumption: { [key: string]: string };
  raw_material_purchase: { [key: string]: string };
  raw_material_amount: { [key: string]: string };
  production_amount: { [key: string]: string };
  internal_id_to_item_code: {
    internalID: string;
    itemText: string;
  };
  spreadsheet_column_filter: {
    "1s": string;
    "2sEid": string;
    "2sEast": string;
    "3s": string;
    "4s": string;
    Management: string;
  };
  selected_date_range: {
    "1s": string;
    "2sEid": string;
    "2sEast": string;
    "3s": string;
    "4s": string;
    Management: string;
  };
  s4_sales_schedule_4s: {
    item: string;
    itemText: string;
    orderQuantity: string;
    scheduledShippingDate: string;
    shippingDestination: string;
  };
  s4_shipping_schedule_4s: {
    instructionQuantity: string;
    item: string;
    itemText: string;
    referenceStartDate: string;
  };
  transfer_item_master: {
    item: string;
  };
};
export const dependSheets: ViewTypeDependSheet<SheetKey, RowKeys>[] = [
  {
    key: "setting",
    displayName: "setting",
    dependSheetFields: [
      {
        key: "optimizationStartDay",
        displayName: "最適化開始日",
        type: "date",
      },
      {
        key: "optimizationEndDay",
        displayName: "最適化終了日",
        type: "date",
      },
      {
        key: "optimizationEo",
        displayName: "最適化_eo",
        type: "boolean",
      },
      {
        key: "optimizationEg",
        displayName: "最適化_eg",
        type: "boolean",
      },
      {
        key: "optimizationNvp",
        displayName: "最適化_nvp",
        type: "boolean",
      },
      {
        key: "optimizationEa",
        displayName: "最適化_ea",
        type: "boolean",
      },
      {
        key: "optimizationEi",
        displayName: "最適化_ei",
        type: "boolean",
      },
      {
        key: "optimizationBhea",
        displayName: "最適化_bhea",
        type: "boolean",
      },
      {
        key: "optimizationEoa",
        displayName: "最適化_eoa",
        type: "boolean",
      },
      {
        key: "optimizationSft",
        displayName: "最適化_sft",
        type: "boolean",
      },
      {
        key: "optimization4s",
        displayName: "最適化_4s",
        type: "boolean",
      },
      {
        key: "optimization4sNoTank",
        displayName: "最適化_4s_タンク無し製品",
        type: "boolean",
      },
      {
        key: "timeCoefficient123s",
        displayName: "実行時間係数(4製以外)",
        type: "float",
      },
      {
        key: "timeCoefficient4s",
        displayName: "実行時間係数(4製)",
        type: "float",
      },
      {
        key: "maxDaysToDivideCalculation",
        displayName: "分割計算時最大分割間隔日数",
        type: "integer",
      },
      {
        key: "maxDaysToOptimizeCalculation",
        displayName: "分割計算時最大最適化日数",
        type: "integer",
      },
      {
        key: "annualPlanMode",
        displayName: "年間計画モード",
        type: "boolean",
      },
    ],
  },
  {
    key: "s4_master_1234s",
    displayName: "s4_master_1234s",
    dependSheetFields: [
      {
        key: "itemCode",
        displayName: "品目コード",
        type: "string",
      },
      {
        key: "itemText",
        displayName: "品目テキスト",
        type: "string",
      },
      {
        key: "version",
        displayName: "製造バージョン",
        type: "string",
      },
      {
        key: "stockArea",
        displayName: "保管場所",
        type: "string",
      },
      {
        key: "lotSize",
        displayName: "ロットサイズ",
        type: "float",
      },
    ],
  },
  {
    key: "parameter",
    displayName: "parameter",
    dependSheetFields: [
      {
        key: "nonEgRateChidori",
        displayName: "非EG化率_千鳥",
        type: "float",
      },
      {
        key: "nonEgRateUkishima",
        displayName: "非EG化率_浮島",
        type: "float",
      },
      {
        key: "egYieldChidori",
        displayName: "MEG-Y計算用EG収率_千鳥",
        type: "float",
      },
      {
        key: "egYieldUkishima",
        displayName: "MEG-Y計算用EG収率_浮島",
        type: "float",
      },
      {
        key: "megRateChidori",
        displayName: "MEG-Y計算用MEG率_千鳥",
        type: "float",
      },
      {
        key: "megRateUkishima",
        displayName: "MEG-Y計算用MEG率_浮島",
        type: "float",
      },
      {
        key: "etUtilizationRateChidori",
        displayName: "Et利用率_千鳥",
        type: "float",
      },
      {
        key: "catalystAmountChidori",
        displayName: "触媒量_千鳥",
        type: "float",
      },
      {
        key: "feedWaterTemperatureChidori",
        displayName: "給水温度_千鳥",
        type: "float",
      },
      {
        key: "gasEnthalpyChidori",
        displayName: "gasエンタルピー_千鳥",
        type: "float",
      },
      {
        key: "ceSChidori",
        displayName: "CE_S_千鳥",
        type: "float",
      },
      {
        key: "ceTChidori",
        displayName: "CE_T_千鳥",
        type: "float",
      },
      {
        key: "steamCorrectionChidori",
        displayName: "蒸気補正値_千鳥",
        type: "float",
      },
      {
        key: "steamCorrectionEa",
        displayName: "EA用蒸気補正値",
        type: "float",
      },
      {
        key: "totalEgSteamConsumptionChidori",
        displayName: "全量EO期間EG消費蒸気_千鳥",
        type: "float",
      },
      {
        key: "eoStopCostPerDayChidori",
        displayName: "EO停止コスト1日あたり単価_千鳥",
        type: "float",
      },
      {
        key: "eoStopCostRestartChidori",
        displayName: "EO停止コスト再スタート単価_千鳥",
        type: "float",
      },
      {
        key: "etUtilizationRateUkishima",
        displayName: "Et利用率_浮島",
        type: "float",
      },
      {
        key: "catalystAmountUkishima",
        displayName: "触媒量_浮島",
        type: "float",
      },
      {
        key: "feedWaterTemperatureUkishima",
        displayName: "給水温度_浮島",
        type: "float",
      },
      {
        key: "gasEnthalpyUkishima",
        displayName: "gasエンタルピー_浮島",
        type: "float",
      },
      {
        key: "ceSUkishima",
        displayName: "CE_S_浮島",
        type: "float",
      },
      {
        key: "ceTUkishima",
        displayName: "CE_T_浮島",
        type: "float",
      },
      {
        key: "steamCorrectionUkishima",
        displayName: "蒸気補正値_浮島",
        type: "float",
      },
      {
        key: "totalEgSteamConsumptionUkishima",
        displayName: "全量EO期間EG消費蒸気_浮島",
        type: "float",
      },
      {
        key: "eoStopCostPerDayUkishima",
        displayName: "EO停止コスト1日あたり単価_浮島",
        type: "float",
      },
      {
        key: "eoStopCostRestartUkishima",
        displayName: "EO停止コスト再スタート単価_浮島",
        type: "float",
      },
      {
        key: "score4sLowerLimit",
        displayName: "4製スコア_在庫下限",
        type: "float",
      },
      {
        key: "score4sUpperLimit",
        displayName: "4製スコア_在庫上限",
        type: "float",
      },
      {
        key: "score4sTargetStockMid",
        displayName: "4製スコア_目標在庫期中",
        type: "float",
      },
      {
        key: "score4sTargetStockEnd",
        displayName: "4製スコア_目標在庫期末",
        type: "float",
      },
      {
        key: "score4sEoConsumptionLowerLimit",
        displayName: "4製スコア_EO消費下限",
        type: "float",
      },
      {
        key: "score4sEoConsumptionUpperLimit",
        displayName: "4製スコア_EO消費上限",
        type: "float",
      },
      {
        key: "score4sEoConsumptionEven",
        displayName: "4製スコア_EO消費平準化",
        type: "float",
      },
      {
        key: "score4sSwitchingTimes",
        displayName: "4製スコア_切替回数",
        type: "float",
      },
      {
        key: "sft70And90ContinuousStopDaysLowerLimit",
        displayName: "SFT70&90_連続停止日数下限",
        type: "integer",
      },
      {
        key: "sft70And90ContinuousStopDaysUpperLimit",
        displayName: "SFT70&90_連続停止日数上限",
        type: "integer",
      },
      {
        key: "sft70ContinuousOperationDaysLowerLimit",
        displayName: "SFT70_連続稼働日数下限",
        type: "integer",
      },
      {
        key: "sft70ContinuousOperationDaysUpperLimit",
        displayName: "SFT70_連続稼働日数上限",
        type: "integer",
      },
      {
        key: "sft90ContinuousOperationDaysLowerLimit",
        displayName: "SFT90_連続稼働日数下限",
        type: "integer",
      },
      {
        key: "sft90ContinuousOperationDaysUpperLimit",
        displayName: "SFT90_連続稼働日数上限",
        type: "integer",
      },
      {
        key: "nvpContinuousStopDaysLowerLimit",
        displayName: "NVP_連続停止日数下限",
        type: "integer",
      },
      {
        key: "nvpContinuousStopDaysUpperLimit",
        displayName: "NVP_連続停止日数上限",
        type: "integer",
      },
      {
        key: "nvpContinuousOperationDaysLowerLimit",
        displayName: "NVP_連続稼働日数下限",
        type: "integer",
      },
      {
        key: "nvpContinuousOperationDaysUpperLimit",
        displayName: "NVP_連続稼働日数上限",
        type: "integer",
      },
      {
        key: "eaContinuousStopDaysLowerLimit",
        displayName: "EA_連続停止日数下限",
        type: "integer",
      },
      {
        key: "eaContinuousStopDaysUpperLimit",
        displayName: "EA_連続停止日数上限",
        type: "integer",
      },
      {
        key: "eaContinuousOperationDaysLowerLimit",
        displayName: "EA_連続稼働日数下限",
        type: "integer",
      },
      {
        key: "eaContinuousOperationDaysUpperLimit",
        displayName: "EA_連続稼働日数上限",
        type: "integer",
      },
    ],
  },
  {
    key: "penalty_weight",
    displayName: "penalty_weight",
    dependSheetFields: [
      {
        key: "item1",
        displayName: "項目1",
        type: "string",
      },
      {
        key: "item2",
        displayName: "項目2",
        type: "string",
      },
      {
        key: "item3",
        displayName: "項目3",
        type: "string",
      },
      {
        key: "penaltyCoefficient",
        displayName: "ペナルティ倍率",
        type: "float",
      },
    ],
  },
  {
    key: "catalyst_parameter",
    displayName: "catalyst_parameter",
    dependSheetFields: [
      {
        key: "factory",
        displayName: "工場",
        type: "enum",
      },
      {
        key: "catalystId",
        displayName: "触媒ID",
        type: "float",
      },
      {
        key: "target",
        displayName: "計算対象",
        type: "enum",
      },
      {
        key: "startEo",
        displayName: "区間開始積算EO量",
        type: "float",
      },
      {
        key: "coefficientA",
        displayName: "係数A",
        type: "float",
      },
      {
        key: "coefficientB",
        displayName: "係数B",
        type: "float",
      },
      {
        key: "coefficientC",
        displayName: "係数C",
        type: "float",
      },
    ],
  },
  {
    key: "product_master",
    displayName: "product_master",
    dependSheetFields: [
      {
        key: "product_id",
        displayName: "product_id",
        type: "string",
      },
      {
        key: "product_name",
        displayName: "product_name",
        type: "string",
      },
      {
        key: "group_name",
        displayName: "group_name",
        type: "string",
      },
      {
        key: "place",
        displayName: "場所",
        type: "string",
      },
      {
        key: "remark",
        displayName: "備考",
        type: "string",
      },
    ],
  },
  {
    key: "product_group_master",
    displayName: "product_group_master",
    dependSheetFields: [
      {
        key: "group_name",
        displayName: "グループ名",
        type: "string",
      },
    ],
  },
  {
    key: "reactor_product_master",
    displayName: "reactor_product_master",
    dependSheetFields: [
      {
        key: "product_id",
        displayName: "製品ID",
        type: "string",
      },
      {
        key: "reactor_id",
        displayName: "反応器ID",
        type: "string",
      },
      {
        key: "reactor_production_time",
        displayName: "バッチあたり生産時間(反応器占有)",
        type: "float",
      },
      {
        key: "shipping_production_time",
        displayName: "バッチあたり生産時間(出荷可能)",
        type: "float",
      },
      {
        key: "production_amount",
        displayName: "バッチあたり生産量",
        type: "float",
      },
      {
        key: "tank_id",
        displayName: "貯蔵タンクID",
        type: "string",
      },
      {
        key: "direct",
        displayName: "直充填かどうか",
        type: "string",
      },
    ],
  },
  {
    key: "material_master",
    displayName: "material_master",
    dependSheetFields: [
      {
        key: "raw_material_id",
        displayName: "原材料ID",
        type: "string",
      },
      {
        key: "raw_material_name",
        displayName: "原材料名",
        type: "string",
      },
      {
        key: "place",
        displayName: "場所",
        type: "string",
      },
      {
        key: "remark",
        displayName: "備考",
        type: "string",
      },
    ],
  },
  {
    key: "product_material_master",
    displayName: "product_material_master",
    dependSheetFields: [
      {
        key: "product_id",
        displayName: "product_id",
        type: "string",
      },
      {
        key: "raw_material_id",
        displayName: "raw_material_id",
        type: "string",
      },
      {
        key: "reactor_id",
        displayName: "reactor_id",
        type: "string",
      },
      {
        key: "required_amount",
        displayName: "required_amount",
        type: "float",
      },
    ],
  },
  {
    key: "tank_master",
    displayName: "tank_master",
    dependSheetFields: [
      {
        key: "tank_name",
        displayName: "tank_name",
        type: "string",
      },
      {
        key: "tank_id",
        displayName: "tank_id",
        type: "string",
      },
      {
        key: "product_id",
        displayName: "product_id",
        type: "string",
      },
      {
        key: "capacity_upper_limit",
        displayName: "capacity_upper_limit",
        type: "float",
      },
      {
        key: "capacity_lower_limit",
        displayName: "capacity_lower_limit",
        type: "float",
      },
      {
        key: "target_stock_amount",
        displayName: "target_stock_amount",
        type: "float",
      },
    ],
  },
  {
    key: "switch_master",
    displayName: "switch_master",
    dependSheetFields: [
      {
        key: "reactor_id",
        displayName: "reactor_id",
        type: "string",
      },
      {
        key: "prev_product_id",
        displayName: "prev_product_id",
        type: "string",
      },
      {
        key: "next_product_id",
        displayName: "next_product_id",
        type: "string",
      },
      {
        key: "switching_time",
        displayName: "switching_time",
        type: "float",
      },
    ],
  },
  {
    key: "unavailable_reactor",
    displayName: "unavailable_reactor",
    dependSheetFields: [
      {
        key: "id",
        displayName: "id",
        type: "string",
      },
      {
        key: "reactor_id",
        displayName: "反応器ID",
        type: "string",
      },
      {
        key: "start_datetime",
        displayName: "使用不可開始日時",
        type: "date-time",
      },
      {
        key: "end_datetime",
        displayName: "使用不可終了日時",
        type: "date-time",
      },
    ],
  },
  {
    key: "unavailable_tank",
    displayName: "unavailable_tank",
    dependSheetFields: [
      {
        key: "tank_id",
        displayName: "タンクID",
        type: "string",
      },
      {
        key: "start_datetime",
        displayName: "使用不可開始日時",
        type: "date-time",
      },
      {
        key: "end_datetime",
        displayName: "使用不可終了日時",
        type: "date-time",
      },
      {
        key: "force_transport",
        displayName: "使用不可時に中身を別タンクに移送する",
        type: "string",
      },
    ],
  },
  {
    key: "general",
    displayName: "general",
    dependSheetFields: [
      {
        key: "date",
        displayName: "date",
        type: "date",
      },
      {
        key: "holiday",
        displayName: "休日",
        type: "string",
      },
      {
        key: "chidoriCatalystID",
        displayName: "用役費千鳥|触媒ID",
        type: "float",
      },
      {
        key: "ukishimaCatalystID",
        displayName: "用役費浮島|触媒ID",
        type: "float",
      },
      {
        key: "chidoriAirFlow",
        displayName: "用役費千鳥|風量",
        type: "float",
      },
      {
        key: "ukishimaAirFlow",
        displayName: "用役費浮島|風量",
        type: "float",
      },
      {
        key: "chidoriSummationEO",
        displayName: "用役費千鳥|EO|積算EO",
        type: "float",
      },
      {
        key: "chidoriElectricityPrice",
        displayName: "用役費千鳥|コスト計算|電力単価",
        type: "float",
      },
      {
        key: "chidoriGasPrice",
        displayName: "用役費千鳥|コスト計算|都市ガス単価",
        type: "float",
      },
      {
        key: "chidoriSteamPrice",
        displayName: "用役費千鳥|コスト計算|蒸気単価",
        type: "float",
      },
      {
        key: "ukishimaSummationEO",
        displayName: "用役費浮島|EO|積算EO",
        type: "float",
      },
      {
        key: "ukishimaElectricityPrice",
        displayName: "用役費浮島|コスト計算|電力単価",
        type: "float",
      },
      {
        key: "ukishimaGasPrice",
        displayName: "用役費浮島|コスト計算|都市ガス単価",
        type: "float",
      },
      {
        key: "ukishimaSteamPrice",
        displayName: "用役費浮島|コスト計算|蒸気単価",
        type: "float",
      },
      {
        key: "ukishimaEOConsumption4s",
        displayName: "EO浮島|EO消費4s",
        type: "float",
      },
      {
        key: "ukishimaMaximumEOConsumption4s",
        displayName: "EO浮島|最大EO消費4s",
        type: "float",
      },
      {
        key: "ukishimaMinimumEOConsumption4s",
        displayName: "EO浮島|最小EO消費4s",
        type: "float",
      },
      {
        key: "sft30Consumption4s",
        displayName: "SFT|SFT30消費4s",
        type: "float",
      },
      {
        key: "sft33Consumption4s",
        displayName: "SFT|SFT33消費4s",
        type: "float",
      },
      {
        key: "sft70Consumption4s",
        displayName: "SFT|SFT70消費4s",
        type: "float",
      },
      {
        key: "sft90Consumption4s",
        displayName: "SFT|SFT90消費4s",
        type: "float",
      },
      {
        key: "shortage4s24k417n",
        displayName: "24K417N|不足量4s",
        type: "float",
      },
      {
        key: "ethyleneCostForEO",
        displayName: "原料コスト|EO用エチレン単価",
        type: "float",
      },
      {
        key: "ethyleneCostForEG",
        displayName: "原料コスト|EG用エチレン単価",
        type: "float",
      },
      {
        key: "EOMarginalProfitPerTonne",
        displayName: "利益計算|トンあたり限界利益EO",
        type: "float",
      },
      {
        key: "domesticMegMarginalProfitPerTonne",
        displayName: "利益計算|トンあたり限界利益MEG国内",
        type: "float",
      },
      {
        key: "exportMegMarginalProfitPerTonne",
        displayName: "利益計算|トンあたり限界利益MEG輸出",
        type: "float",
      },
      {
        key: "domesticDegMarginalProfitPerTonne",
        displayName: "利益計算|トンあたり限界利益DEG国内",
        type: "float",
      },
      {
        key: "exportDegMarginalProfitPerTonne",
        displayName: "利益計算|トンあたり限界利益DEG輸出",
        type: "float",
      },
      {
        key: "tegMarginalProfitPerTonne",
        displayName: "利益計算|トンあたり限界利益TEG",
        type: "float",
      },
      {
        key: "traegMarginalProfitPerTonne",
        displayName: "利益計算|トンあたり限界利益TraEG",
        type: "float",
      },
      {
        key: "nparaffinCost",
        displayName: "原料コスト|ノルマルパラフィン単価",
        type: "float",
      },
      {
        key: "ammoniaCost",
        displayName: "原料コスト|アンモニア単価",
        type: "float",
      },
      {
        key: "calcCost000",
        displayName: "用役費総合計",
        type: "float",
      },
      {
        key: "calcCost001",
        displayName: "原料コスト|EO用エチレン費用千鳥",
        type: "float",
      },
      {
        key: "calcCost002",
        displayName: "原料コスト|EO用エチレン費用浮島",
        type: "float",
      },
      {
        key: "calcCost003",
        displayName: "原料コスト|EG用エチレン費用千鳥",
        type: "float",
      },
      {
        key: "calcCost004",
        displayName: "原料コスト|EG用エチレン費用浮島",
        type: "float",
      },
      {
        key: "calcCost005",
        displayName: "利益計算|限界利益EO千鳥",
        type: "float",
      },
      {
        key: "calcCost006",
        displayName: "利益計算|限界利益EO浮島",
        type: "float",
      },
      {
        key: "calcCost007",
        displayName: "利益計算|限界利益MEG千鳥国内",
        type: "float",
      },
      {
        key: "calcCost008",
        displayName: "利益計算|限界利益MEG浮島国内",
        type: "float",
      },
      {
        key: "calcCost009",
        displayName: "利益計算|限界利益MEG千鳥輸出",
        type: "float",
      },
      {
        key: "calcCost010",
        displayName: "利益計算|限界利益MEG浮島輸出",
        type: "float",
      },
      {
        key: "calcCost011",
        displayName: "利益計算|限界利益DEG千鳥国内",
        type: "float",
      },
      {
        key: "calcCost012",
        displayName: "利益計算|限界利益DEG浮島国内",
        type: "float",
      },
      {
        key: "calcCost013",
        displayName: "利益計算|限界利益DEG千鳥輸出",
        type: "float",
      },
      {
        key: "calcCost014",
        displayName: "利益計算|限界利益DEG浮島輸出",
        type: "float",
      },
      {
        key: "calcCost015",
        displayName: "利益計算|限界利益TEG浮島",
        type: "float",
      },
      {
        key: "calcCost016",
        displayName: "利益計算|限界利益TraEG浮島",
        type: "float",
      },
      {
        key: "calcCost017",
        displayName: "原料コスト|ノルマルパラフィン費用",
        type: "float",
      },
      {
        key: "calcCost018",
        displayName: "原料コスト|アンモニア費用",
        type: "float",
      },
      {
        key: "calcCost019",
        displayName: "用役費千鳥|EO|Et投入量",
        type: "float",
      },
      {
        key: "calcCost020",
        displayName: "用役費千鳥|EO|生成EO",
        type: "float",
      },
      {
        key: "calcCost021",
        displayName: "用役費千鳥|EO|CE(400T/D換算)",
        type: "float",
      },
      {
        key: "calcCost022",
        displayName: "用役費千鳥|EO|CE(投入Et量における値)",
        type: "float",
      },
      {
        key: "calcCost023",
        displayName: "用役費千鳥|EO|選択率(400T/D換算)",
        type: "float",
      },
      {
        key: "calcCost024",
        displayName: "用役費千鳥|EO|熱水温度(400T/D換算)",
        type: "float",
      },
      {
        key: "calcCost025",
        displayName: "用役費千鳥|EO|選択率(投入Et量における値)",
        type: "float",
      },
      {
        key: "calcCost026",
        displayName: "用役費千鳥|EO|熱水温度(投入Et量における値)",
        type: "float",
      },
      {
        key: "calcCost027",
        displayName: "用役費千鳥|反応熱|EO生成",
        type: "float",
      },
      {
        key: "calcCost028",
        displayName: "用役費千鳥|反応熱|CO2生成",
        type: "float",
      },
      {
        key: "calcCost029",
        displayName: "用役費千鳥|熱水蒸気潜熱",
        type: "float",
      },
      {
        key: "calcCost030",
        displayName: "用役費千鳥|発生蒸気量",
        type: "float",
      },
      {
        key: "calcCost031",
        displayName: "用役費千鳥|生産計画の値|選択率",
        type: "float",
      },
      {
        key: "calcCost032",
        displayName: "用役費千鳥|生産計画の値|生成EO",
        type: "float",
      },
      {
        key: "calcCost033",
        displayName: "用役費千鳥|計算には使用しない|ｶﾞｽｴﾝﾀﾙﾋﾟｰ",
        type: "float",
      },
      {
        key: "calcCost034",
        displayName: "用役費千鳥|反応熱|給水量",
        type: "float",
      },
      {
        key: "calcCost035",
        displayName: "用役費千鳥|反応熱|吸水温度",
        type: "float",
      },
      {
        key: "calcCost036",
        displayName: "用役費千鳥|反応熱|吸水顕熱",
        type: "float",
      },
      {
        key: "calcCost037",
        displayName: "用役費千鳥|反応熱|error.",
        type: "float",
      },
      {
        key: "calcCost038",
        displayName: "用役費千鳥|加水EO量",
        type: "float",
      },
      {
        key: "calcCost039",
        displayName: "用役費千鳥|精製EO",
        type: "float",
      },
      {
        key: "calcCost040",
        displayName: "用役費千鳥|熱水温度",
        type: "float",
      },
      {
        key: "calcCost041",
        displayName: "用役費千鳥|蒸気圧力",
        type: "float",
      },
      {
        key: "calcCost042",
        displayName: "用役費千鳥|発生蒸気",
        type: "float",
      },
      {
        key: "calcCost043",
        displayName: "用役費千鳥|ｺｼﾞｪﾈ|都市ｶﾞｽ",
        type: "float",
      },
      {
        key: "calcCost044",
        displayName: "用役費千鳥|ｺｼﾞｪﾈ|都市ｶﾞｽ(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost045",
        displayName: "用役費千鳥|ｺｼﾞｪﾈ|発電量",
        type: "float",
      },
      {
        key: "calcCost046",
        displayName: "用役費千鳥|ｺｼﾞｪﾈ|発電量(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost047",
        displayName: "用役費千鳥|ｺｼﾞｪﾈ|熱回収",
        type: "float",
      },
      {
        key: "calcCost048",
        displayName: "用役費千鳥|A-4010|都市ｶﾞｽ",
        type: "float",
      },
      {
        key: "calcCost049",
        displayName: "用役費千鳥|A-4010|都市ｶﾞｽ(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost050",
        displayName: "用役費千鳥|A-4020|都市ｶﾞｽ",
        type: "float",
      },
      {
        key: "calcCost051",
        displayName: "用役費千鳥|A-4020|都市ｶﾞｽ(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost052",
        displayName: "用役費千鳥|O2消費",
        type: "float",
      },
      {
        key: "calcCost053",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|KC-10",
        type: "float",
      },
      {
        key: "calcCost054",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|KC-10(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost055",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|KC-40",
        type: "float",
      },
      {
        key: "calcCost056",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|KC-40(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost057",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|LCO2-1",
        type: "float",
      },
      {
        key: "calcCost058",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|LCO2-2",
        type: "float",
      },
      {
        key: "calcCost059",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|O2低圧",
        type: "float",
      },
      {
        key: "calcCost060",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|O2低圧(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost061",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|DO-1(発電量)",
        type: "float",
      },
      {
        key: "calcCost062",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|DO-1(発電量)(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost063",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|O2ﾌﾟﾗﾝﾄ合計",
        type: "float",
      },
      {
        key: "calcCost064",
        displayName: "用役費千鳥|EO系|T-4103(0.2M)",
        type: "float",
      },
      {
        key: "calcCost065",
        displayName: "用役費千鳥|EO系|T-4103(0.2M)(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost066",
        displayName: "用役費千鳥|EO系|T-4106(0.2M)",
        type: "float",
      },
      {
        key: "calcCost067",
        displayName: "用役費千鳥|EO系|K-4160B",
        type: "float",
      },
      {
        key: "calcCost068",
        displayName: "用役費千鳥|EO系|T-4109(0.9M)",
        type: "float",
      },
      {
        key: "calcCost069",
        displayName: "用役費千鳥|EO系|T-4109(0.9M)(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost070",
        displayName: "用役費千鳥|EO系|EO系合計",
        type: "float",
      },
      {
        key: "calcCost071",
        displayName: "用役費千鳥|EO系|K-4165A",
        type: "float",
      },
      {
        key: "calcCost072",
        displayName: "用役費千鳥|EO系|K-4165A(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost073",
        displayName: "用役費千鳥|EO系|K-4165B",
        type: "float",
      },
      {
        key: "calcCost074",
        displayName: "用役費千鳥|EO系|K-4165B(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost075",
        displayName: "用役費千鳥|EO系|EO電力合計",
        type: "float",
      },
      {
        key: "calcCost076",
        displayName: "用役費千鳥|EG系|消費蒸気(0.9M)",
        type: "float",
      },
      {
        key: "calcCost077",
        displayName: "用役費千鳥|EG系|消費蒸気(0.9M)(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost078",
        displayName: "用役費千鳥|EG系|消費蒸気(0.2M)",
        type: "float",
      },
      {
        key: "calcCost079",
        displayName: "用役費千鳥|EG系|消費蒸気(0.2M)(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost080",
        displayName: "用役費千鳥|EA系|EA稼働",
        type: "float",
      },
      {
        key: "calcCost081",
        displayName: "用役費千鳥|EA系|消費蒸気(0.9M)",
        type: "float",
      },
      {
        key: "calcCost082",
        displayName: "用役費千鳥|EA系|消費蒸気(0.2M)",
        type: "float",
      },
      {
        key: "calcCost083",
        displayName: "用役費千鳥|EI系|生産量",
        type: "float",
      },
      {
        key: "calcCost084",
        displayName: "用役費千鳥|EI系|消費蒸気(0.9M)",
        type: "float",
      },
      {
        key: "calcCost085",
        displayName: "用役費千鳥|EI系|消費蒸気(0.2M)",
        type: "float",
      },
      {
        key: "calcCost086",
        displayName: "用役費千鳥|NVP系|消費蒸気",
        type: "float",
      },
      {
        key: "calcCost087",
        displayName: "用役費千鳥|EID|消費蒸気",
        type: "float",
      },
      {
        key: "calcCost088",
        displayName: "用役費千鳥|用役集計|発生蒸気",
        type: "float",
      },
      {
        key: "calcCost089",
        displayName: "用役費千鳥|用役集計|0.9M消費蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost090",
        displayName: "用役費千鳥|用役集計|0.9M消費蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost091",
        displayName: "用役費千鳥|用役集計|0.9M固定蒸気",
        type: "float",
      },
      {
        key: "calcCost092",
        displayName: "用役費千鳥|用役集計|0.9M→2.2M換算消費蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost093",
        displayName: "用役費千鳥|用役集計|0.9M→2.2M換算消費蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost094",
        displayName: "用役費千鳥|用役集計|0.2M消費蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost095",
        displayName: "用役費千鳥|用役集計|0.2M消費蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost096",
        displayName: "用役費千鳥|用役集計|0.2M固定蒸気",
        type: "float",
      },
      {
        key: "calcCost097",
        displayName: "用役費千鳥|用役集計|0.2M→2.2M換算消費蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost098",
        displayName: "用役費千鳥|用役集計|0.2M→2.2M換算消費蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost099",
        displayName: "用役費千鳥|計算ベース|購入蒸気",
        type: "float",
      },
      {
        key: "calcCost100",
        displayName: "用役費千鳥|計算ベース|ﾊﾟｰｼﾞ蒸気",
        type: "float",
      },
      {
        key: "calcCost101",
        displayName: "用役費千鳥|計算ベース|購入蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost102",
        displayName: "用役費千鳥|計算ベース|購入蒸気(定常運転+スタート停止)",
        type: "float",
      },
      {
        key: "calcCost103",
        displayName: "用役費千鳥|計算ベース|0.9M換算購入蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost104",
        displayName: "用役費千鳥|計算ベース|0.9M換算購入蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost105",
        displayName: "用役費千鳥|計算ベース|都市ｶﾞｽ購入",
        type: "float",
      },
      {
        key: "calcCost106",
        displayName: "用役費千鳥|計算ベース|換算後",
        type: "float",
      },
      {
        key: "calcCost107",
        displayName: "用役費千鳥|計算ベース|消費電力",
        type: "float",
      },
      {
        key: "calcCost108",
        displayName: "用役費千鳥|コスト計算|電力コスト",
        type: "float",
      },
      {
        key: "calcCost109",
        displayName: "用役費千鳥|コスト計算|都市ガスコスト",
        type: "float",
      },
      {
        key: "calcCost110",
        displayName: "用役費千鳥|コスト計算|蒸気コスト",
        type: "float",
      },
      {
        key: "calcCost111",
        displayName: "用役費千鳥|コスト計算|EO停止コスト",
        type: "float",
      },
      {
        key: "calcCost112",
        displayName: "用役費千鳥|コスト計算|合計コスト",
        type: "float",
      },
      {
        key: "calcCost113",
        displayName: "用役費浮島|EO|Et投入量",
        type: "float",
      },
      {
        key: "calcCost114",
        displayName: "用役費浮島|EO|生成EO",
        type: "float",
      },
      {
        key: "calcCost115",
        displayName: "用役費浮島|EO|CE(400T/D換算)",
        type: "float",
      },
      {
        key: "calcCost116",
        displayName: "用役費浮島|EO|CE(投入Et量における値)",
        type: "float",
      },
      {
        key: "calcCost117",
        displayName: "用役費浮島|EO|選択率(400T/D換算)",
        type: "float",
      },
      {
        key: "calcCost118",
        displayName: "用役費浮島|EO|熱水温度(400T/D換算)",
        type: "float",
      },
      {
        key: "calcCost119",
        displayName: "用役費浮島|EO|選択率(投入Et量における値)",
        type: "float",
      },
      {
        key: "calcCost120",
        displayName: "用役費浮島|EO|熱水温度(投入Et量における値)",
        type: "float",
      },
      {
        key: "calcCost121",
        displayName: "用役費浮島|反応熱|EO生成",
        type: "float",
      },
      {
        key: "calcCost122",
        displayName: "用役費浮島|反応熱|CO2生成",
        type: "float",
      },
      {
        key: "calcCost123",
        displayName: "用役費浮島|熱水蒸気潜熱",
        type: "float",
      },
      {
        key: "calcCost124",
        displayName: "用役費浮島|発生蒸気量",
        type: "float",
      },
      {
        key: "calcCost125",
        displayName: "用役費浮島|生産計画の値|選択率",
        type: "float",
      },
      {
        key: "calcCost126",
        displayName: "用役費浮島|生産計画の値|生成EO",
        type: "float",
      },
      {
        key: "calcCost127",
        displayName: "用役費浮島|ｶﾞｽｴﾝﾀﾙﾋﾟｰ",
        type: "float",
      },
      {
        key: "calcCost128",
        displayName: "用役費浮島|給水量",
        type: "float",
      },
      {
        key: "calcCost129",
        displayName: "用役費浮島|吸水温度",
        type: "float",
      },
      {
        key: "calcCost130",
        displayName: "用役費浮島|吸水顕熱",
        type: "float",
      },
      {
        key: "calcCost131",
        displayName: "用役費浮島|error.",
        type: "float",
      },
      {
        key: "calcCost132",
        displayName: "用役費浮島|加水EO量",
        type: "float",
      },
      {
        key: "calcCost133",
        displayName: "用役費浮島|T合稼働",
        type: "float",
      },
      {
        key: "calcCost134",
        displayName: "用役費浮島|A-9処理増分",
        type: "float",
      },
      {
        key: "calcCost135",
        displayName: "用役費浮島|精製EO",
        type: "float",
      },
      {
        key: "calcCost136",
        displayName: "用役費浮島|熱水温度",
        type: "float",
      },
      {
        key: "calcCost137",
        displayName: "用役費浮島|蒸気圧力",
        type: "float",
      },
      {
        key: "calcCost138",
        displayName: "用役費浮島|発生蒸気",
        type: "float",
      },
      {
        key: "calcCost139",
        displayName: "用役費浮島|発生蒸気|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost140",
        displayName: "用役費浮島|消費蒸気|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost141",
        displayName: "用役費浮島|K-2006|投入蒸気",
        type: "float",
      },
      {
        key: "calcCost142",
        displayName: "用役費浮島|K-2006|発生-FI2412",
        type: "float",
      },
      {
        key: "calcCost143",
        displayName: "用役費浮島|K-2006(見直し)|投入蒸気",
        type: "float",
      },
      {
        key: "calcCost144",
        displayName: "用役費浮島|K-2006(見直し)|発電量",
        type: "float",
      },
      {
        key: "calcCost145",
        displayName: "用役費浮島|K-2006(見直し)|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost146",
        displayName: "用役費浮島|A-2001|都市ｶﾞｽ",
        type: "float",
      },
      {
        key: "calcCost147",
        displayName: "用役費浮島|A-2001|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost148",
        displayName: "用役費浮島|4O+4X|消費電力",
        type: "float",
      },
      {
        key: "calcCost149",
        displayName: "用役費浮島|4O+4X|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost150",
        displayName: "用役費浮島|EG系|0.9M 消費",
        type: "float",
      },
      {
        key: "calcCost151",
        displayName: "用役費浮島|ﾛｰﾋｰﾄ(0.9M)|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost152",
        displayName: "用役費浮島|リボイラー(0.2M)|CO2放散塔蒸気",
        type: "float",
      },
      {
        key: "calcCost153",
        displayName: "用役費浮島|リボイラー(0.2M)|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost154",
        displayName: "用役費浮島|FI2056|FI2056",
        type: "float",
      },
      {
        key: "calcCost155",
        displayName: "用役費浮島|FI2056|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost156",
        displayName: "用役費浮島|O2消費",
        type: "float",
      },
      {
        key: "calcCost157",
        displayName: "用役費浮島|O2ﾌﾟﾗﾝﾄ|消費電力",
        type: "float",
      },
      {
        key: "calcCost158",
        displayName: "用役費浮島|O2ﾌﾟﾗﾝﾄ|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost159",
        displayName: "用役費浮島|LCO2系|LCO2ﾛｰﾄﾞ",
        type: "float",
      },
      {
        key: "calcCost160",
        displayName: "用役費浮島|LCO2系|消費電力",
        type: "float",
      },
      {
        key: "calcCost161",
        displayName: "用役費浮島|LCO2系|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost162",
        displayName: "用役費浮島|X系|EO放散塔",
        type: "float",
      },
      {
        key: "calcCost163",
        displayName: "用役費浮島|X系|EO放散塔 (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost164",
        displayName: "用役費浮島|X系|FI2119",
        type: "float",
      },
      {
        key: "calcCost165",
        displayName: "用役費浮島|X系|FI2119 (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost166",
        displayName: "用役費浮島|X系|X系その他",
        type: "float",
      },
      {
        key: "calcCost167",
        displayName: "用役費浮島|X系|X系その他 (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost168",
        displayName: "用役費浮島|X系|FI2008",
        type: "float",
      },
      {
        key: "calcCost169",
        displayName: "用役費浮島|X系|FI2008 (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost170",
        displayName: "用役費浮島|EG系(0.9MPa)|0.9M 消費",
        type: "float",
      },
      {
        key: "calcCost171",
        displayName: "用役費浮島|EG系(0.9MPa)|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost172",
        displayName: "用役費浮島|EG系(0.2MPa)|FC2217",
        type: "float",
      },
      {
        key: "calcCost173",
        displayName: "用役費浮島|EG系(0.2MPa)|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost174",
        displayName: "用役費浮島|EG系(2.5MPa)|T-2402蒸気",
        type: "float",
      },
      {
        key: "calcCost175",
        displayName: "用役費浮島|EG系(2.5MPa)|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost176",
        displayName: "用役費浮島|EG系(2.5M→0.9MPa換算)|T-2402蒸気",
        type: "float",
      },
      {
        key: "calcCost177",
        displayName: "用役費浮島|EG系(2.5M→0.9MPa換算)|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost178",
        displayName: "用役費浮島|EG系(都市ガス)|DT 都市ｶﾞｽ",
        type: "float",
      },
      {
        key: "calcCost179",
        displayName: "用役費浮島|EG系(都市ガス)|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost180",
        displayName: "用役費浮島|HA系(SFT30)|消費EO",
        type: "float",
      },
      {
        key: "calcCost181",
        displayName: "用役費浮島|HA系(HEO)|消費EO",
        type: "float",
      },
      {
        key: "calcCost182",
        displayName: "用役費浮島|HA系(SFT30)|消費蒸気(0.2M)",
        type: "float",
      },
      {
        key: "calcCost183",
        displayName: "用役費浮島|HA系(SFT30)|消費蒸気(0.2M) (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost184",
        displayName: "用役費浮島|HA系(SFT30)|消費蒸気(0.9M)",
        type: "float",
      },
      {
        key: "calcCost185",
        displayName: "用役費浮島|HA系(SFT30)|消費蒸気(0.9M) (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost186",
        displayName: "用役費浮島|HA系(SFT30)|消費電力",
        type: "float",
      },
      {
        key: "calcCost187",
        displayName: "用役費浮島|HA系(SFT30)|消費電力 (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost188",
        displayName: "用役費浮島|HA系(HEO)|消費電力",
        type: "float",
      },
      {
        key: "calcCost189",
        displayName: "用役費浮島|HA系(HEO)|消費電力 (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost190",
        displayName: "用役費浮島|HA系(SFT30)|都市ｶﾞｽ",
        type: "float",
      },
      {
        key: "calcCost191",
        displayName: "用役費浮島|HA系(SFT30)|都市ｶﾞｽ (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost192",
        displayName: "用役費浮島|4S|消費EO",
        type: "float",
      },
      {
        key: "calcCost193",
        displayName: "用役費浮島|4S|消費蒸気(0.2M)",
        type: "float",
      },
      {
        key: "calcCost194",
        displayName: "用役費浮島|4S|消費蒸気(0.2M)(ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost195",
        displayName: "用役費浮島|4S|消費蒸気(0.9M)",
        type: "float",
      },
      {
        key: "calcCost196",
        displayName: "用役費浮島|4S|消費蒸気(0.9M)(ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost197",
        displayName: "用役費浮島|4S|消費電力",
        type: "float",
      },
      {
        key: "calcCost198",
        displayName: "用役費浮島|4S|消費電力(ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost199",
        displayName: "用役費浮島|用役集計|発生蒸気",
        type: "float",
      },
      {
        key: "calcCost200",
        displayName: "用役費浮島|用役集計|0.9M消費蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost201",
        displayName: "用役費浮島|用役集計|0.9M消費蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost202",
        displayName: "用役費浮島|用役集計|0.9M→2.2M換算消費蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost203",
        displayName: "用役費浮島|用役集計|0.9M→2.2M換算消費蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost204",
        displayName: "用役費浮島|用役集計|0.2M消費蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost205",
        displayName: "用役費浮島|用役集計|0.2M消費蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost206",
        displayName: "用役費浮島|用役集計|0.2M→2.2M換算消費蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost207",
        displayName: "用役費浮島|用役集計|0.2M→2.2M換算消費蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost208",
        displayName: "用役費浮島|計算ベース|蒸気パージ(旧)",
        type: "float",
      },
      {
        key: "calcCost209",
        displayName: "用役費浮島|計算ベース|蒸気購入量(旧)",
        type: "float",
      },
      {
        key: "calcCost210",
        displayName: "用役費浮島|計算ベース|蒸気パージ",
        type: "float",
      },
      {
        key: "calcCost211",
        displayName: "用役費浮島|計算ベース|蒸気購入量(定常運転)",
        type: "float",
      },
      {
        key: "calcCost212",
        displayName: "用役費浮島|計算ベース|蒸気購入量(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost213",
        displayName: "用役費浮島|計算ベース|蒸気購入量(定常運転+スタート停止)",
        type: "float",
      },
      {
        key: "calcCost214",
        displayName: "用役費浮島|計算ベース|蒸気購入量(0.9M換算)(定常運転)",
        type: "float",
      },
      {
        key: "calcCost215",
        displayName: "用役費浮島|計算ベース|蒸気購入量(0.9M換算)(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost216",
        displayName: "用役費浮島|計算ベース|電気購入量",
        type: "float",
      },
      {
        key: "calcCost217",
        displayName: "用役費浮島|計算ベース|都市ｶﾞｽ購入",
        type: "float",
      },
      {
        key: "calcCost218",
        displayName: "用役費浮島|コスト計算|電力コスト",
        type: "float",
      },
      {
        key: "calcCost219",
        displayName: "用役費浮島|コスト計算|都市ガスコスト",
        type: "float",
      },
      {
        key: "calcCost220",
        displayName: "用役費浮島|コスト計算|蒸気コスト",
        type: "float",
      },
      {
        key: "calcCost221",
        displayName: "用役費浮島|コスト計算|EO停止コスト",
        type: "float",
      },
      {
        key: "calcCost222",
        displayName: "用役費浮島|コスト計算|合計コスト",
        type: "float",
      },
    ],
  },
  {
    key: "production",
    displayName: "production",
    dependSheetFields: [
      {
        key: "reactor_id",
        displayName: "反応器ID",
        type: "string",
      },
      {
        key: "product_id",
        displayName: "製品ID",
        type: "string",
      },
      {
        key: "product_name",
        displayName: "製品名",
        type: "string",
      },
      {
        key: "tank_id",
        displayName: "タンクID",
        type: "string",
      },
      {
        key: "start_datetime",
        displayName: "生産開始日時",
        type: "date-time",
      },
      {
        key: "end_datetime",
        displayName: "生産終了日時",
        type: "date-time",
      },
      {
        key: "production_type",
        displayName: "スケジュールのタイプ",
        type: "string",
      },
      {
        key: "is_fixed",
        displayName: "固定スケジュールかどうか",
        type: "boolean",
      },
      {
        key: "task_id",
        displayName: "タスクID",
        type: "string",
      },
      {
        key: "comment",
        displayName: "コメント",
        type: "string",
      },
    ],
  },
  {
    key: "transport",
    displayName: "transport",
    dependSheetFields: [
      {
        key: "datetime",
        displayName: "日時",
        type: "date-time",
      },
      {
        key: "tank_id_from",
        displayName: "移送元タンクID",
        type: "string",
      },
      {
        key: "tank_id_to",
        displayName: "移送先タンクID",
        type: "string",
      },
      {
        key: "amount",
        displayName: "移送量",
        type: "float",
      },
      {
        key: "is_fixed",
        displayName: "固定スケジュールかどうか",
        type: "boolean",
      },
      {
        key: "task_id",
        displayName: "タスクID",
        type: "string",
      },
    ],
  },
  {
    key: "shipping_schedule",
    displayName: "shipping_schedule",
    isReadAll: true,
  },
  {
    key: "tank_amount",
    displayName: "tank_amount",
    isReadAll: true,
  },
  {
    key: "product_tank_amount",
    displayName: "product_tank_amount",
    isReadAll: true,
  },
  {
    key: "material_consumption_by_product",
    displayName: "material_consumption_by_product",
    isReadAll: true,
  },
  {
    key: "material_consumption_by_group",
    displayName: "material_consumption_by_group",
    isReadAll: true,
  },
  {
    key: "raw_material_consumption",
    displayName: "raw_material_consumption",
    isReadAll: true,
  },
  {
    key: "raw_material_purchase",
    displayName: "raw_material_purchase",
    isReadAll: true,
  },
  {
    key: "raw_material_amount",
    displayName: "raw_material_amount",
    isReadAll: true,
  },
  {
    key: "production_amount",
    displayName: "production_amount",
    isReadAll: true,
  },
  {
    key: "internal_id_to_item_code",
    displayName: "internal_id_to_item_code",
    dependSheetFields: [
      {
        key: "internalID",
        displayName: "内部ID",
        type: "string",
      },
      {
        key: "itemText",
        displayName: "品目コード",
        type: "string",
      },
    ],
  },
  {
    key: "spreadsheet_column_filter",
    displayName: "spreadsheet_column_filter",
    dependSheetFields: [
      {
        key: "1s",
        displayName: "1s列フィルタ",
        type: "string",
      },
      {
        key: "2sEid",
        displayName: "2sEid列フィルタ",
        type: "string",
      },
      {
        key: "2sEast",
        displayName: "2sEast列フィルタ",
        type: "string",
      },
      {
        key: "3s",
        displayName: "3s列フィルタ",
        type: "string",
      },
      {
        key: "4s",
        displayName: "4s列フィルタ",
        type: "string",
      },
      {
        key: "Management",
        displayName: "生産管理センターの列フィルタ",
        type: "string",
      },
    ],
  },
  {
    key: "selected_date_range",
    displayName: "selected_date_range",
    dependSheetFields: [
      {
        key: "1s",
        displayName: "1s日付範囲",
        type: "string",
      },
      {
        key: "2sEid",
        displayName: "2sEid日付範囲",
        type: "string",
      },
      {
        key: "2sEast",
        displayName: "2sEast日付範囲",
        type: "string",
      },
      {
        key: "3s",
        displayName: "3s日付範囲",
        type: "string",
      },
      {
        key: "4s",
        displayName: "4s日付範囲",
        type: "string",
      },
      {
        key: "Management",
        displayName: "Management日付範囲",
        type: "string",
      },
    ],
  },
  {
    key: "s4_sales_schedule_4s",
    displayName: "s4_sales_schedule_4s",
    dependSheetFields: [
      {
        key: "item",
        displayName: "品目",
        type: "string",
      },
      {
        key: "itemText",
        displayName: "品目テキスト",
        type: "string",
      },
      {
        key: "orderQuantity",
        displayName: "受注数量",
        type: "float",
      },
      {
        key: "scheduledShippingDate",
        displayName: "出荷予定日",
        type: "date",
      },
      {
        key: "shippingDestination",
        displayName: "出荷先",
        type: "string",
      },
    ],
  },
  {
    key: "s4_shipping_schedule_4s",
    displayName: "s4_shipping_schedule_4s",
    dependSheetFields: [
      {
        key: "instructionQuantity",
        displayName: "指図数量",
        type: "float",
      },
      {
        key: "item",
        displayName: "品目",
        type: "string",
      },
      {
        key: "itemText",
        displayName: "品目テキスト",
        type: "string",
      },
      {
        key: "referenceStartDate",
        displayName: "基準開始日",
        type: "date",
      },
    ],
  },
  {
    key: "transfer_item_master",
    displayName: "transfer_item_master",
    dependSheetFields: [
      {
        key: "item",
        displayName: "品目",
        type: "string",
      },
    ],
  },
  {
    key: "productSheetWithout4sMetaData",
    displayName: "4s以外製品シートメタデータ",
    dependSheetFields: [
      {
        key: "item_id",
        displayName: "項目ID",
        type: "float",
      },
      {
        key: "en_item_name",
        displayName: "英語項目名",
        type: "string",
      },
      {
        key: "item_name",
        displayName: "項目名",
        type: "string",
      },
      {
        key: "item_kind",
        displayName: "項目種別",
        type: "string",
      },
      {
        key: "data_type",
        displayName: "データ型",
        type: "enum",
      },
      {
        key: "enum_params",
        displayName: "enum選択肢",
        type: "string",
      },
      {
        key: "algorithm_kind",
        displayName: "分類",
        type: "string",
      },
      {
        key: "target_item_category",
        displayName: "対象品目カテゴリ",
        type: "string",
      },
      {
        key: "target_item",
        displayName: "対象品目",
        type: "string",
      },
      {
        key: "consume_item_category",
        displayName: "対象品目を消費する品目カテゴリ",
        type: "string",
      },
      {
        key: "consume_item",
        displayName: "対象品目を消費する品目",
        type: "string",
      },
      {
        key: "reactor",
        displayName: "装置",
        type: "string",
      },
      {
        key: "tank_id",
        displayName: "関連タンクID",
        type: "string",
      },
      {
        key: "department",
        displayName: "部署",
        type: "string",
      },
      {
        key: "output_kind",
        displayName: "出力種別",
        type: "string",
      },
    ],
  },
];
export const loadSkipSheets: {
  sheetKey: keyof viewtypeContents;
  sheetFieldSchemaName: string;
}[] = [];
export const sheetTypes: {
  sheetKey: keyof viewtypeContents;
  sheetType: "setting" | "master" | "plan" | "unuse" | "summary" | "edit_only";
}[] = [
  {
    sheetKey: "setting",
    sheetType: "edit_only",
  },
  {
    sheetKey: "parameter",
    sheetType: "setting",
  },
  {
    sheetKey: "penalty_weight",
    sheetType: "setting",
  },
  {
    sheetKey: "catalyst_parameter",
    sheetType: "master",
  },
  {
    sheetKey: "product_master",
    sheetType: "master",
  },
  {
    sheetKey: "product_group_master",
    sheetType: "master",
  },
  {
    sheetKey: "reactor_product_master",
    sheetType: "master",
  },
  {
    sheetKey: "material_master",
    sheetType: "master",
  },
  {
    sheetKey: "product_material_master",
    sheetType: "master",
  },
  {
    sheetKey: "tank_master",
    sheetType: "master",
  },
  {
    sheetKey: "switch_master",
    sheetType: "master",
  },
  {
    sheetKey: "unavailable_reactor",
    sheetType: "master",
  },
  {
    sheetKey: "unavailable_tank",
    sheetType: "master",
  },
  {
    sheetKey: "general",
    sheetType: "plan",
  },
  {
    sheetKey: "production",
    sheetType: "plan",
  },
  {
    sheetKey: "transport",
    sheetType: "plan",
  },
  {
    sheetKey: "shipping_schedule",
    sheetType: "plan",
  },
  {
    sheetKey: "tank_amount",
    sheetType: "plan",
  },
  {
    sheetKey: "product_tank_amount",
    sheetType: "plan",
  },
  {
    sheetKey: "material_consumption_by_product",
    sheetType: "plan",
  },
  {
    sheetKey: "material_consumption_by_group",
    sheetType: "plan",
  },
  {
    sheetKey: "raw_material_consumption",
    sheetType: "plan",
  },
  {
    sheetKey: "raw_material_purchase",
    sheetType: "edit_only",
  },
  {
    sheetKey: "raw_material_amount",
    sheetType: "edit_only",
  },
  {
    sheetKey: "production_amount",
    sheetType: "plan",
  },
  {
    sheetKey: "internal_id_to_item_code",
    sheetType: "edit_only",
  },
  {
    sheetKey: "spreadsheet_column_filter",
    sheetType: "edit_only",
  },
  {
    sheetKey: "selected_date_range",
    sheetType: "edit_only",
  },
  {
    sheetKey: "s4_sales_schedule_4s",
    sheetType: "plan",
  },
  {
    sheetKey: "s4_shipping_schedule_4s",
    sheetType: "plan",
  },
  {
    sheetKey: "transfer_item_master",
    sheetType: "master",
  },
  {
    sheetKey: "productSheetWithout4sMetaData",
    sheetType: "edit_only",
  },
  {
    sheetKey: "s4_master_1234s",
    sheetType: "master",
  },
];
export const spreadSheetSettings: { [k: string]: SpreadSheetSetting } = {
  setting: {
    name: "setting",
    sheetType: "edit_only",
    sheetKeys: ["setting"],
    columns: "all",
    queryLoadSheets: [],
  },
  parameter: {
    name: "parameter",
    sheetType: "setting",
    sheetKeys: ["parameter"],
    columns: "all",
    queryLoadSheets: [],
  },
  penalty_weight: {
    name: "penalty_weight",
    sheetType: "setting",
    sheetKeys: ["penalty_weight"],
    columns: "all",
    queryLoadSheets: [],
  },
  catalyst_parameter: {
    name: "catalyst_parameter",
    sheetType: "master",
    sheetKeys: ["catalyst_parameter"],
    columns: "all",
    queryLoadSheets: [],
  },
  product_master: {
    name: "product_master",
    sheetType: "master",
    sheetKeys: ["product_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  product_group_master: {
    name: "product_group_master",
    sheetType: "master",
    sheetKeys: ["product_group_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  reactor_product_master: {
    name: "reactor_product_master",
    sheetType: "master",
    sheetKeys: ["reactor_product_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  material_master: {
    name: "material_master",
    sheetType: "master",
    sheetKeys: ["material_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  product_material_master: {
    name: "product_material_master",
    sheetType: "master",
    sheetKeys: ["product_material_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  tank_master: {
    name: "tank_master",
    sheetType: "master",
    sheetKeys: ["tank_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  switch_master: {
    name: "switch_master",
    sheetType: "master",
    sheetKeys: ["switch_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  unavailable_reactor: {
    name: "unavailable_reactor",
    sheetType: "master",
    sheetKeys: ["unavailable_reactor"],
    columns: "all",
    queryLoadSheets: [],
  },
  unavailable_tank: {
    name: "unavailable_tank",
    sheetType: "master",
    sheetKeys: ["unavailable_tank"],
    columns: "all",
    queryLoadSheets: [],
  },
  general: {
    name: "general",
    sheetType: "plan",
    sheetKeys: ["general"],
    columns: "all",
    queryLoadSheets: [],
  },
  production: {
    name: "production",
    sheetType: "plan",
    sheetKeys: ["production"],
    columns: "all",
    queryLoadSheets: [],
  },
  transport: {
    name: "transport",
    sheetType: "plan",
    sheetKeys: ["transport"],
    columns: "all",
    queryLoadSheets: [],
  },
  shipping_schedule: {
    name: "shipping_schedule",
    sheetType: "plan",
    sheetKeys: ["shipping_schedule"],
    columns: "all",
    queryLoadSheets: [],
  },
  tank_amount: {
    name: "tank_amount",
    sheetType: "plan",
    sheetKeys: ["tank_amount"],
    columns: "all",
    queryLoadSheets: [],
  },
  product_tank_amount: {
    name: "product_tank_amount",
    sheetType: "plan",
    sheetKeys: ["product_tank_amount"],
    columns: "all",
    queryLoadSheets: [],
  },
  material_consumption_by_product: {
    name: "material_consumption_by_product",
    sheetType: "plan",
    sheetKeys: ["material_consumption_by_product"],
    columns: "all",
    queryLoadSheets: [],
  },
  material_consumption_by_group: {
    name: "material_consumption_by_group",
    sheetType: "plan",
    sheetKeys: ["material_consumption_by_group"],
    columns: "all",
    queryLoadSheets: [],
  },
  raw_material_consumption: {
    name: "raw_material_consumption",
    sheetType: "plan",
    sheetKeys: ["raw_material_consumption"],
    columns: "all",
    queryLoadSheets: [],
  },
  raw_material_purchase: {
    name: "raw_material_purchase",
    sheetType: "edit_only",
    sheetKeys: ["raw_material_purchase"],
    columns: "all",
    queryLoadSheets: [],
  },
  raw_material_amount: {
    name: "raw_material_amount",
    sheetType: "edit_only",
    sheetKeys: ["raw_material_amount"],
    columns: "all",
    queryLoadSheets: [],
  },
  production_amount: {
    name: "production_amount",
    sheetType: "plan",
    sheetKeys: ["production_amount"],
    columns: "all",
    queryLoadSheets: [],
  },
  internal_id_to_item_code: {
    name: "internal_id_to_item_code",
    sheetType: "edit_only",
    sheetKeys: ["internal_id_to_item_code"],
    columns: "all",
    queryLoadSheets: [],
  },
  spreadsheet_column_filter: {
    name: "spreadsheet_column_filter",
    sheetType: "edit_only",
    sheetKeys: ["spreadsheet_column_filter"],
    columns: "all",
    queryLoadSheets: [],
  },
  s4_master_1234s: {
    name: "s4_master_1234s",
    sheetType: "master",
    sheetKeys: ["s4_master_1234s"],
    columns: "all",
    queryLoadSheets: [],
  },
  selected_date_range: {
    name: "selected_date_range",
    sheetType: "edit_only",
    sheetKeys: ["selected_date_range"],
    columns: "all",
    queryLoadSheets: [],
  },
  s4_sales_schedule_4s: {
    name: "s4_sales_schedule_4s",
    sheetType: "plan",
    sheetKeys: ["s4_sales_schedule_4s"],
    columns: "all",
    queryLoadSheets: [],
  },
  s4_shipping_schedule_4s: {
    name: "s4_shipping_schedule_4s",
    sheetType: "plan",
    sheetKeys: ["s4_shipping_schedule_4s"],
    columns: "all",
    queryLoadSheets: [],
  },
  transfer_item_master: {
    name: "transfer_item_master",
    sheetType: "master",
    sheetKeys: ["transfer_item_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  productSheetWithout4sMetaData: {
    name: "productSheetWithout4sMetaData",
    sheetType: "edit_only",
    sheetKeys: ["productSheetWithout4sMetaData"],
    columns: "all",
    queryLoadSheets: [],
  },
};

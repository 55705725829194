import { ViewType } from "@/domain/ViewType";
import { viewTypeNshKws2sGanttChart } from "@/viewType/viewTypes/Optium/nshKws/2sGanttChart";
import { viewTypeNshKws4sGanttChart } from "@/viewType/viewTypes/Optium/nshKws/views/4sGanttChart";
import { viewTypeNshKwsCSVView1s } from "@/viewType/viewTypes/Optium/nshKws/views/CSVView1s";
import { viewTypeNshKwsCSVView2sEast } from "@/viewType/viewTypes/Optium/nshKws/views/CSVView2sEast";
import { viewTypeNshKwsCSVView2sEid } from "@/viewType/viewTypes/Optium/nshKws/views/CSVView2sEid";
import { viewTypeNshKwsCSVView3s } from "@/viewType/viewTypes/Optium/nshKws/views/CSVView3s";
import { viewTypeNshKwsCSVView4s } from "@/viewType/viewTypes/Optium/nshKws/views/CSVView4s";
import { viewTypeNshKwsCSVViewFinancial } from "@/viewType/viewTypes/Optium/nshKws/views/CSVViewFinancial";
import { viewTypeNshKwsCSVViewManagement } from "@/viewType/viewTypes/Optium/nshKws/views/CSVViewManagement";

import { viewTypeNshKwsSummary } from "@/viewType/viewTypes/Optium/nshKws/views/SummaryView";
import { viewTypeNshKwsSpreadSheetAndGraph1s } from "./SpreadSheetAndGraph/views/1s";
import { viewTypeNshKwsSpreadSheetAndGraph2sEast } from "./SpreadSheetAndGraph/views/2s_east";
import { viewTypeNshKwsSpreadSheetAndGraph2sEid } from "./SpreadSheetAndGraph/views/2s_eid";
import { viewTypeNshKwsSpreadSheetAndGraph3s } from "./SpreadSheetAndGraph/views/3s";
import { viewTypeNshKwsSpreadSheetAndGraph4s } from "./SpreadSheetAndGraph/views/4s";
import { viewTypeNshKwsSpreadSheetAndGraphManagement } from "./SpreadSheetAndGraph/views/management";
/**
 * @description 各ビューの定義を列挙する。
 * 新しくviewを定義したらここに追加する
 */
export const viewTypesNshKws: ViewType<any, any>[] = [
  viewTypeNshKws4sGanttChart,
  viewTypeNshKws2sGanttChart,
  viewTypeNshKwsCSVViewManagement,
  viewTypeNshKwsCSVView1s,
  viewTypeNshKwsCSVView2sEid,
  viewTypeNshKwsCSVView2sEast,
  viewTypeNshKwsCSVView3s,
  viewTypeNshKwsCSVView4s,
  viewTypeNshKwsCSVViewFinancial,
  viewTypeNshKwsSpreadSheetAndGraph1s,
  viewTypeNshKwsSpreadSheetAndGraph2sEid,
  viewTypeNshKwsSpreadSheetAndGraph2sEast,
  viewTypeNshKwsSpreadSheetAndGraph3s,
  viewTypeNshKwsSpreadSheetAndGraph4s,
  viewTypeNshKwsSpreadSheetAndGraphManagement,
  viewTypeNshKwsSummary,
];

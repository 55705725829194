import axios, { isAxiosError, AxiosResponse } from "axios";

const baseURL = (() => {
  switch (process.env.APP_ENV) {
    case "staging":
      return "https://api.stg-sheets.algo-artis.ai/v1";
    case "production":
      return "https://api.sheets.algo-artis.ai/v1";
    default:
      return "https://api.dev-sheets.algo-artis.ai/v1";
  }
})();

export const API_CONFIG = (() => {
  switch (process.env.APP_ENV) {
    case "staging": {
      return {
        URL_LOGOUT: `https://account.stg-sheets.algo-artis.ai/v2/logout?returnTo=${location.origin}&client_id=vbPULwGrR805Yjx89Q9H2n84u6opTySK`,
        baseURL,
        API_KEY: "AIzaSyCOA7UNxHYCLHjSr1vQGUSIxlCN6cVXnFc",
      };
    }
    case "production": {
      return {
        URL_LOGOUT: `https://account.sheets.algo-artis.ai/v2/logout?returnTo=${location.origin}&client_id=MtJnFJ7h1XjdyMuknUVupGWZPo5Tfxrj`,
        baseURL,
        API_KEY: "AIzaSyDe56NyJqk60i1CIui4fP89wi5liwGzzMc",
      };
    }
    default: {
      return {
        URL_LOGOUT: `https://account.dev-sheets.algo-artis.ai/v2/logout?returnTo=${location.origin}&client_id=dX9OcxQiFz59V9hgLZuSY2tamvxgatlS`,
        baseURL,
        API_KEY: "AIzaSyCaxeMwNkHNLUMvNP5qku8KCsoNvIHV0Ao",
      };
    }
  }
})();

export const API_CLIENT = axios.create({
  baseURL,
});

export const API_CLIENT_WITHOUT_IDTOKEN = axios.create({
  baseURL,
});

function axiosResponse(response: AxiosResponse<any, any>) {
  return response;
}

function axiosErrorReject(error: any) {
  // レスポンスエラーではなくaxiosのエラーの場合の対応
  if (isAxiosError(error)) {
    const { params, headers } = error.config ?? {
      params: null,
      headers: null,
    };
    const headersForErrorMsg = { ...headers };
    // NOTE: headersにはAPIキーやアクセストークンが含まれるため、非表示
    if (headersForErrorMsg) {
      delete headersForErrorMsg["X-API-KEY"];
      delete headersForErrorMsg["Authorization"];
    }
    error.message += ` [ ${
      (error?.config?.baseURL?.replace(/\/$/, "") || "") + error?.config?.url
    }, ${JSON.stringify({ params, headers: headersForErrorMsg })}]`;
  }
  return Promise.reject(error);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// NOTE: requestに関するinterceptorsはAppAuthProviderで設定
API_CLIENT.interceptors.response.use(axiosResponse, axiosErrorReject);

// idtokenやAPIキーをリクエスト前に取得して付与する前処理
API_CLIENT_WITHOUT_IDTOKEN.interceptors.request.use(async (config) => {
  // APIキーをヘッダーに追加
  config.headers["X-API-KEY"] = API_CONFIG.API_KEY;
  return config;
});

API_CLIENT_WITHOUT_IDTOKEN.interceptors.response.use(
  axiosResponse,
  axiosErrorReject
);
